/* Customizations to the designer theme should be defined here */
.p-ripple {
    overflow: hidden;
    position: relative
}

.p-ink {
    display: block;
    position: absolute;
    background: variableValues($neutral, "10");
    border-radius: 100%;
    transform: scale(0)
}

.p-ink-active {
    animation: ripple .4s linear
}

.p-ripple-disabled .p-ink {
    display: none !important
}

.p-hidden-accessible {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }