.rwa__steps {
    .p-steps {
      &-item {
        display: flex;
        justify-content: center;
        position: relative;
        flex: 1 1 auto;
        min-width: rem(150);
        &:before {
          border-top-width: rem(1);
          content: ' ';
          width: 100%;
          top: rem(54);
          display: block;
          position: absolute;
          transform: translateY(-1.4rem);
        }
        &:last-child {
          flex: 1 1 auto;
          &:before {
            border-top-width: rem(1);
            content: ' ';
            width: 100%;
            top: rem(54);
            display: block;
            position: absolute;
            transform: translateY(-1.4rem);
          }
        }
        .p-menuitem {
          &-link {
            background-color: transparent;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            flex: 0 1 auto;
            overflow: hidden;
            text-decoration: none;
            .p-steps {
              &-number {
                color: variableValues($neutral, '60');
                border: 0;
                min-width: rem(32);
                height: rem(32);
                line-height: rem(24);
                background: variableValues($neutral, '30');
                font-size: rem(16);
              }
  
              &-title {
                font-weight: variableValues($weights, '400');
                margin-top: rem(12);
                color: variableValues($neutral, '70');
              }
            }
          }
        }
        &.icon-check {
          .p-steps-number {
            position: relative;
            color: transparent !important;
            background-color: variableValues($teal, '60') !important;
            border-color: variableValues($teal, '60') !important;
            &:before {
              content: '\f00c';
              font-family: 'Font Awesome 6 Pro';
              display: inline-block;
              position: absolute;
              vertical-align: middle;
              color: #fff;
              font-weight: 900;
              text-indent: 0;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
  
        &.p-disabled{
          .p-menuitem-link{
            .p-steps-number{
              background-color: variableValues($neutral, '30') !important;
            }
          }
        }
  
        .step-completed.p-menuitem-link {
          .p-steps-number {
            background-color: rgb(0, 165, 163) !important;
            font-size: 0;
            &:before {
              content: '\f00c';
              font-family: 'Font Awesome 6 Pro';
              font-size: rem(14);
              display: inline-block;
              position: absolute;
              vertical-align: middle;
              color: #fff;
              font-weight: 900;
              text-indent: 0;
              left: 50%;
              top: 43%;
              transform: translate(-50%, -50%);
            }
          }
        }
  
        &.p-disabled {
          opacity: 1;
        }
      }
      &-current {
        .p-steps {
          &-number {
            background: variableValues($purple, '10') !important;
            color: variableValues($purple, '60') !important;
            border: rem(1) solid variableValues($purple, '60') !important;
          }
          &-title {
            font-weight: variableValues($weights, '500') !important;
            color: variableValues($neutral, '90') !important;
          }
        }
      }
      &.step-completed {
        background-color: rgb(0, 165, 163) !important;
        color: #fff !important;
      }
    }
  }
  .ltr {
    .rwa__steps {
      .p-steps {
        &-item {
          &:before {
            left: 0;
          }
  
          &:first-child {
            &:before {
              left: 50%;
            }
          }
          &:last-child {
            &:before {
              left: -50%;
            }
          }
        }
      }
    }
  }
  
  .rtl{
    .rwa__steps {
      .p-steps {
        &-item {
          &:before {
           right: 0;
          }
    
          &:first-child {
            &:before {
              right: 50%;
            }
          }
          &:last-child {
            &:before {
              right: -50%;
            }
          }
        }
      }
    }
    
  }
  
  @for $i from 1 through 10 {
    .rwa__steps-complete-#{$i} {
      .p-steps-item:nth-child(-n + #{$i}) .p-menuitem-link .p-steps-number {
        background-color: variableValues($purple, '70') !important;
        font-size: 0;
        &:before {
          color: white;
          content: '';
          font-family: variableValues($fonts, "icons");
          font-size: rem(16);
        }
      }
    }
  }