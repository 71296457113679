//===========================================
// -Variables start
//===========================================
@import './mixins';

// Breakpoints
$breakpoints: (
  'xs': 320px,
  'sm': 640px,
  'md': 960px,
  'lg': 1366px,
);

// Fonts
$fonts: (
    "english": 'Roboto',
    "arabic": 'Montserrat',
    "icons": 'Font Awesome 6 Pro',
    "pi": 'primeicons',
);

// Font Sizes
$sizes: (
    "1": 44,
    "2": 36,
    "3": 32,
    "4": 28,
    "5": 24,
    "6": 20,
    "7": 18,
    "8": 16,
    "9": 14,
    "10": 12,
    "11": 10,
    "12": 8,
);

// Font Weight
$weights: (
    "400": 400,
    "500": 500,
    "600": 600,
    "700": 700,
);

// Base
$black: #000000;
$white: #ffffff;

// Colors
$purple: (
    "10": #F7EAF4,
    "20": #F8DCF1,
    "30": #F0BDED,
    "40": #C679C5,
    "50": #A45DA3,
    "60": #824481,
    "70": #561157,
    "80": #37003D,
    "90": #220D22,
);

$teal: (
    "10": #EEF8F8,
    "20": #D5ECEC,
    "30": #BDEFEF,
    "40": #9DEFEF,
    "50": #00BFBC,
    "60": #00A5A3,
    "70": #00807D,
    "80": #005E5D,
    "90": #094040,
);

$neutral: (
    "10": #F4F6F6,
    "20": #E3E8E8,
    "30": #D2DADA,
    "40": #C7CCCC,
    "50": #AFB6B6,
    "60": #8D9696,
    "70": #5C7070,
    "80": #313D3D,
    "90": #0D2222,
);

$green: (
    "10": #EBFFF5,
    "20": #DCFFDE,
    "30": #C2FFE0,
    "40": #99FFCC,
    "50": #45D98F,
    "60": #14A85E,
    "70": #008547,
    "80": #006636,
    "90": #00391A,
);

$yellow: (
    "10": #FFF9EB,
    "20": #FFF0D1,
    "30": #FCE5A9,
    "40": #FFD469,
    "50": #FCC63D,
    "60": #D9A200,
    "70": #996B00,
    "80": #402D00,
    "90": #322300,
    "100": #8D5A5A
);

$red: (
    "10": #FFF0ED,
    "20": #FFDCD6,
    "30": #FFBFB5,
    "40": #FF897A,
    "50": #E44D47,
    "60": #D61F1F,
    "70": #BA1B1B,
    "80": #930006,
    "90": #680003,
);

$blue: (
    "10": #EBF5FF,
    "20": #CFE7FF,
    "30": #A3D1FF,
    "40": #66B3FF,
    "50": #2196F3,
    "60": #007ACC,
    "70": #005C99,
    "80": #00325A,
    "90": #002644,
);
$lightapricot: (
  '10': #fff3dc,
  '20': #ffebc6,
  '30': #ffe2b0,
  '40': #ffd999,
  '50': #ffd183,
  '60': #604506,
  '70': #ffc056,
  '80': #ffb740,
  '90': #ffae2a,
  '100': #ffa514,
);

// Text Colors
$textColors: (
    "primary": variableValues($neutral, "80"),
    "secondary": variableValues($neutral, "70"),
    "placeholder": variableValues($neutral, "50"),
    "helper": variableValues($neutral, "60"),
    "on-color": $white,
    "error": variableValues($red, "60"),
    "inverse": $white,
    "on-color-disabled": variableValues($neutral, "50"),
);

// Border Radius
$border-radius: (
    "0": 0,
    "1": 4px,
    "2": 6px,
    "3": 12px,
    "4": 18px,
    "5": 50px,
);

//Widths
$widths: (
    "10": 10%,
    "20": 20%,
    "30": 30%,
    "40": 40%,
    "50": 50%,
    "60": 60%,
    "70": 70%,
    "80": 80%,
    "90": 90%,
    "100": 100%,
);

// define your vars or override bootstrap vars
$primary: variableValues($purple, "70");
$primary-dark: variableValues($purple, "80");
$primary-darker: variableValues($purple, "90");
$primary-text: $white;
$sidenav-background: variableValues($purple, "90");
$header-height: 64px;

$font-path: "../fonts";

// ===========================================
// -Variables end
// ===========================================
