//===========================================
// -Breadcrumb start
//===========================================
.p-breadcrumb {
    font-size: 14px;
}
.rewaa-icon {
    display: inline-block;
    line-height: 1;
    &::before {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: inline-block;
        box-shadow: 0px 0px 6px rgba($black, 0.08);
        content: "";
        background: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.71187 0.454012H3.98987C3.83597 0.447909 3.68282 0.478545 3.54311 0.543383C3.4034 0.608222 3.28114 0.7054 3.18644 0.826876C3.09174 0.948351 3.02733 1.09063 2.99854 1.24194C2.96975 1.39325 2.97741 1.54925 3.02088 1.69701L7.90287 16.648C7.97561 16.8465 8.10755 17.0179 8.28086 17.139C8.45416 17.26 8.66047 17.325 8.87188 17.325C9.08328 17.325 9.28959 17.26 9.4629 17.139C9.6362 17.0179 9.76815 16.8465 9.84088 16.648L13.5279 5.35401C13.6991 4.77184 13.7293 4.15728 13.616 3.56113C13.5026 2.96498 13.2491 2.40435 12.8762 1.92559C12.5034 1.44682 12.0219 1.06366 11.4717 0.807779C10.9215 0.551894 10.3182 0.430631 9.71187 0.454012Z" fill="%2378CAD5"/><mask id="mask0_1148_12983" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="4" y="2" width="12" height="18"><path d="M4.896 2.44141H15.701V19.5464H4.896V2.44141Z" fill="white"/></mask><g mask="url(%23mask0_1148_12983)"><g opacity="0.9"><path d="M11.801 2.44132H5.81201C5.67304 2.43602 5.53481 2.46385 5.40872 2.52251C5.28262 2.58116 5.17228 2.66896 5.08681 2.77866C5.00134 2.88836 4.94318 3.01681 4.91713 3.15342C4.89108 3.29002 4.8979 3.43086 4.93701 3.56432L9.95301 18.9353C10.0185 19.1146 10.1375 19.2695 10.2939 19.3789C10.4503 19.4883 10.6366 19.5469 10.8275 19.5469C11.0184 19.5469 11.2047 19.4883 11.3611 19.3789C11.5175 19.2695 11.6365 19.1146 11.702 18.9353L15.527 7.22332C15.6941 6.65501 15.7236 6.05508 15.6129 5.47314C15.5022 4.8912 15.2546 4.34397 14.8905 3.87669C14.5264 3.40942 14.0563 3.03553 13.5191 2.78593C12.9818 2.53634 12.3929 2.41821 11.801 2.44132Z" fill="%2300A5A3"/></g></g></svg>');
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: center center;
    }
}

//===========================================
// -Breadcrumb end
//===========================================
