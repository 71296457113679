$colors: (
    "blue": #2196F3,
    "green": #4caf50,
    "yellow": #FBC02D,
    "cyan": #00BCD4,
    "pink": #E91E63,
    "indigo": #3F51B5,
    "teal": #009688,
    "orange": #F57C00,
    "bluegray": #607D8B,
    "purple": $primary,
    "red": #FF4032,
    "primary": $primary
);

// Mandatory Designer Variables
@import './variables/general';
@import './variables/form';
@import './variables/button';
@import './variables/panel';
@import './variables/_data';
@import './variables/_overlay';
@import './variables/_message';
@import './variables/_menu';
@import './variables/_media';
@import './variables/_misc';

:root {
    font-family:#{variableValues($fonts, "english")};
    --font-family:#{variableValues($fonts, "english")};
    --text-color:#495057;
    --text-color-secondary:#6c757d;
    --primary-color: #{$primary};
    --primary-color-text:#{$primary-text};
    --surface-10: #{$white};
    --surface-20: #FAFAFA;
    --surface-20: #EEEEEE;
    --surface-30: #E0E0E0;
    --surface-40: #BDBDBD;
    --surface-50: #9E9E9E;
    --surface-60: #757575;
    --surface-70: #616161;
    --surface-80: #424242;
    --surface-90: #212121;
    --content-padding:#{$panelContentPadding};
    --inline-spacing:#{$inlineSpacing};
    --border-radius:#{$borderRadius};
    --surface-ground:#f8f9fa;
    --surface-section:#{$white};
    --surface-card:#{$white};
    --surface-overlay:#{$white};
    --maskbg: #{$maskBg};
    --highlight-bg: #{$highlightBg};
    --highlight-text-color: #{$highlightTextColor};
    --focus-ring: #{$focusShadow};
    color-scheme: light;
}
