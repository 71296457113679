// Generate Font Sizes Classes
@each $name, $value in $sizes {
    .rw__fs-#{$name} {
      font-size: rem($value) !important;
    }
}

// Generate Font Weight Class
@each $name, $value in $weights {
    .fw-#{$name} {
      font-weight: $value !important;
    }
}

// Headings
$headings: (
    "h1" variableValues($sizes, "1") variableValues($weights, "500") 0 1,
    "h2" variableValues($sizes, "3") variableValues($weights, "500") 0 1,
    "h3" variableValues($sizes, "5") variableValues($weights, "500") 0 1,
    "h4" variableValues($sizes, "6") variableValues($weights, "500") 0 1,
    "h5" variableValues($sizes, "8") variableValues($weights, "500") 0 1,
    "h6" variableValues($sizes, "9") variableValues($weights, "500") 0 1,
);

// Generate Headings Class
@each $name, $size, $weight, $letterSpacing, $lineHeight in $headings {
    #{$name}, .#{$name} {
      font-size: rem($size);
      font-weight: $weight;
      letter-spacing: rem($letterSpacing);
      line-height: $lineHeight;
      margin: 0;
    }
}

// Text Align 
$textAlignments: (
    "text-start" "text-align" "start",
    "text-center" "text-align" "center",
    "text-end" "text-align" "end",
);

// Generate Text Alignment Class
@each $name, $title, $value in $textAlignments {
    #{$name} {
      #{$title}: #{$value};
    }
}

// Generate Text Color Classes

// Function to merge maps with keys
@function merge-maps($maps...) {
    $result: ();
    @each $map, $object-name in $maps {
        @each $key, $value in $map {
            $result: map-merge($result, (
                ".text-#{$object-name}-#{$key}": $value
            ));
        }
    }
    @return $result;
}

$colors: merge-maps(
  ($purple, "purple"),
  ($teal, "teal"),
  ($neutral, "neutral"),
  ($green, "green"),
  ($yellow, "yellow"),
  ($red, "red"),
  ($blue, "blue")
); // Combine all color maps into one with keys


// Output the classes
@each $class, $color in $colors {
  #{$class} {
    color: $color;
  }
}

// Remove list styles on ul, ol elements with a class attribute
ul[class],
ol[class] {
  padding-left: 0;
  list-style: none;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
  &:last-child {
    margin-bottom: 0;
  }
}