p-dialog {
    .p-dialog-header {
        border-bottom: 0;
    }
    .p-dialog-footer {
        border-top: 0;
    }
    &.content-space-0 {
        .p-dialog-content {
            padding: 0;
        }
    }
}