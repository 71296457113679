//===========================================
// -Forms Start
//===========================================
// PrimeNG
.p-input-icon-left, .p-input-icon-right {
    position: relative;
    display: inline-block;
    > i,
    > .p-icon-wrapper,
    > i,
    > .p-icon-wrapper {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

// .p-inputnumber {
//     border-radius: variableValues($border-radius, "1");
//     border: 1px solid variableValues($purple, "90");
//     .p-inputnumber-input {
//         border: 0;
//         text-align: center;
//     }
// }

textarea {
    border-color: variableValues($neutral, "20");
    border-radius: variableValues($border-radius, "1");
    font-family: variableValues($fonts, "english");
    padding: rem(16);
    outline: none;
    &:hover,
    &:focus {
        border-color: variableValues($purple, "70");
    }
}

.p-selectbutton {
    .p-button {
        color: variableValues($neutral, "90");
        font-size: rem(14);
        padding: rem(8) rem(16);
        &.p-highlight {
            color: white;
        }
        &:not(.p-highlight) {
            border-color: variableValues($neutral, "20");
        }
    }
}

.p-inputnumber,
.p-dropdown,
.p-inputtext {
    border-color: variableValues($neutral, "20");
    border-radius: variableValues($border-radius, "1");
    &:hover,
    &:focus {
        border-color: variableValues($purple, "70");
    }
}

.p-inputtext {
    padding-top: rem(9);
    padding-bottom: rem(9);
}

.p-calendar {
    .p-datepicker-trigger {
        background-color: variableValues($purple, "10");
        border-left: 0;
        border-color: variableValues($neutral, "20");
        width: rem(50);
        span {
            color: variableValues($purple, "70");
            font-size: rem(20);
        }
    }
    &-w-btn {
        .p-inputtext {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

.showRedAsterisk {
    position:relative;
    .p-inputtext {
        padding-left: rem(22);
    }
    &:before{
        content: "*";
        color: red;
        font-size: rem(14);
        position: absolute;
        top: rem(8);
        left: rem(10);
        z-index: 1;
    }
}

.showGreyAsterisk {
    position:relative;
    .p-inputtext {
        padding-left: rem(22);
    }
    &:before{
        content: "*";
        color: grey;
        font-size: rem(14);
        position: absolute;
        top: rem(8);
        left: rem(10);
        z-index: 1;
    }
}

.p-inputwrapper {
    .p-inputnumber-buttons-horizontal {
        .p-inputnumber-input {
            width: 100%;
        }
    }
}

.showAsterisk {
    position:relative;
    .p-inputtext {
        padding-left: rem(22);
    }
    &:before{
        content: "*";
        color: red;
        font-size: rem(14);
        position: absolute;
        top: rem(8);
        left: rem(10);
        z-index: 1;
    }
}

.input-toggle-box {
    padding: rem(12) rem(16);
    border-radius: variableValues($border-radius, "1");
    border: rem(1) solid variableValues($neutral, "30");
    display: flex;
    align-items: center;
    gap: 8px;
    .p-radiobutton-box {
        background: transparent;
    }
    &-active,
    &.checked {
        background-color: variableValues($purple, "10");
        border: 1px solid variableValues($purple, "60");
    }
}

.p-menu-overlay {
    padding: 0;
    li {
        .p-menuitem-content:hover {
            background-color: variableValues($neutral, "10");
        }
        a {
            color: variableValues($neutral, "70");
            font-size: rem(14);
        }
    }
}

//===========================================
// -Forms end
//===========================================
