//=======================================================
// -Global style start
//=======================================================

// Default style start
*,
*::before,
*::after {
  box-sizing: inherit;
}
::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #D2DADA;
  border-radius: 10px;
  border: solid 4px white;
}

::-webkit-scrollbar-thumb:hover {
  background: #a6a6a6;
}

html,
body {
  box-sizing: border-box;
  font-family: variableValues($fonts, "english");
  min-height: 100vh;
  margin: 0;
  font-size: 16px;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

// Make images easier to work with
img {
  max-width: 100%;
  display: block;
}

//=======================================================
// -Global style  end
//=======================================================
